import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import NetworkIndicator from "./NetworkIndicator";
import ProgressBar from "./ProgressBar";
import SwipeField from "./SwipeField";

const Swipe = () => {
  let navigate = useNavigate();

  return (
    <MotionDiv className="h-full">
      <div className="h-full bg-landqart-darkBrown">
        <ProgressBar />
        <SwipeField />
        <NetworkIndicator />
        <div className="absolute top-0 left-0 z-40">
          <BackButton onClick={() => navigate("/tutorial")} />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Swipe;
