import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpg";
import instruction1 from "../../assets/img/02_onboarding/tutorial_landqart-place.json";
import instruction2 from "../../assets/img/02_onboarding/tutorial_landqart-swipe.json";

const Tutorial = () => {
  let navigate = useNavigate();
  const [nextSlide, setNextSlide] = useState<boolean>();

  const dotVariants = {
    full: { width: 18, height: 18, backgroundColor: "#E6D1AF" },
    empty: { width: 12, height: 12, backgroundColor: "#FF000000" },
  };

  return (
    <MotionDiv className="h-full">
      <div
        className="flex flex-col items-center w-full h-full pt-10 pb-10 bg-center bg-cover text-landqart-lightBrown"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute top-0 z-20 flex items-center justify-between w-screen">
          <BackButton
            onClick={() => {
              if (!nextSlide) {
                navigate("/", { replace: true });
              } else {
                setNextSlide(nextSlide ? !nextSlide : true);
              }
            }}
          />

          <button
            onClick={() => {
              navigate("/swipe", { replace: true });
            }}
            className="px-6 py-4 text-2xl font-medium"
          >
            Skip
          </button>
        </div>

        <div className="mt-10">
          <div className="flex items-center justify-center w-screen">
            {!nextSlide && (
              <MotionDiv key="slide1">
                <span className="text-4xl">Place Banknote</span>
              </MotionDiv>
            )}
            {nextSlide && (
              <MotionDiv key="slide2">
                <span className="text-4xl">Swipe</span>
              </MotionDiv>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center flex-grow w-screen">
          <AnimatePresence exitBeforeEnter>
            {!nextSlide && (
              <MotionDiv
                initial={nextSlide != null ? "initialOnLeft" : "initialVisible"}
                animate="slideInHorizontal"
                exit="slideOutToLeft"
                key="image1"
                className="flex-grow object-contain w-screen bg-center bg-no-repeat"
              >
                <Lottie animationData={instruction1} play={true} loop={true} className="object-contain h-[50vh]" />
              </MotionDiv>
            )}
            {nextSlide && (
              <MotionDiv
                initial="initialOnRight"
                animate="slideInHorizontal"
                exit="slideOutToRight"
                key="image2"
                className="flex-grow object-contain w-screen bg-center bg-no-repeat"
              >
                <Lottie animationData={instruction2} play={true} loop={true} className="object-contain h-[50vh]" />
              </MotionDiv>
            )}
          </AnimatePresence>
        </div>

        <div className="flex items-center h-8 my-4 space-x-3">
          <AnimatePresence>
            <motion.div
              key="dot1"
              className="border-2 rounded-full border-landqart-lightBrown"
              variants={dotVariants}
              animate={nextSlide ? "empty" : "full"}
              transition={{ type: "linear" }}
            ></motion.div>
            <motion.div
              key="dot2"
              className="border-2 rounded-full border-landqart-lightBrown"
              variants={dotVariants}
              animate={!nextSlide ? "empty" : "full"}
              transition={{ type: "linear" }}
            ></motion.div>
          </AnimatePresence>
        </div>
        <Button
          title={!nextSlide ? "Continue" : "Start"}
          onClick={() => {
            if (!nextSlide) {
              setNextSlide(nextSlide ? !nextSlide : true);
            } else {
              navigate("/swipe", { replace: true });
            }
          }}
          className="w-56 uppercase"
        />
      </div>
    </MotionDiv>
  );
};

export default Tutorial;
