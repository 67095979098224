import { ReactNode } from "react";
import rotateDevice from "../../assets/img/00_general/rotate-device.png";

interface Props {
  children: ReactNode;
}

const WithOrientationNotice = (props: Props) => {
  return (
    <>
      <div className="absolute flex items-center justify-center w-screen min-h-full portrait:hidden">
        <img src={rotateDevice} alt="Please rotate your device." className="h-40" />
      </div>

      <div className="landscape:hidden">{props.children}</div>
    </>
  );
};

export default WithOrientationNotice;
