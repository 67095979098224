import clsx from "clsx";
import { useRef } from "react";

import click from "../../../assets/sounds/klick.mp3";
import Sound from "../../Sound";

// types
type Props = {
  title: string;
  onClick: any;
  className?: string;
  disabled?: boolean;
  primary?: boolean;
};

// component
const Button = (props: Props) => {
  const clickSound = useRef(new Sound(click));

  return (
    <button
      type="button"
      className={clsx(
        "inline-flex items-center px-3 pb-2 pt-3 rounded-full justify-center align-baseline text-2xl",
        { "cursor-not-allowed": props.disabled },
        { "": !props.disabled },
        // primary, enabled
        {
          "text-landqart-green border-landqart-green border-2 bg-landqart-darkBrown": props.primary && !props.disabled,
        },
        // primary, disabled
        {
          "text-white bg-landqart-green bg-opacity-50": props.primary && props.disabled,
        },
        // secondary, enabled
        {
          "text-landqart-green bg-landqart-darkBrown": !props.primary && !props.disabled,
        },
        // secondary, disabled
        {
          "text-landqart-green bg-white": !props.primary && props.disabled,
        },

        props.className
      )}
      onClick={() => {
        clickSound.current.play();
        props.onClick();
      }}
      disabled={props.disabled}
    >
      {props.title}
    </button>
  );
};

Button.defaultProps = {
  primary: true,
};

export default Button;
