import { ConnectivityResponse, UsabilityResponse } from "@prismadelabs/prismaid";
import { AnimatePresence, motion, useDragControls } from "framer-motion";
import { useMotionTimeline } from "motion-hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sound from "../../../components/Sound";
import ScaledImage from "../../../components/UI/images/ScaledImage";
import ScaledImageUnpositioned from "../../../components/UI/images/ScaledImageUnpositioned";
import Spinner from "../../../components/UI/Spinner";
import { getScaleFactor, getScaleFactorFromLocalStorage } from "../../../helper/scale";
import useTimeout from "../../../hooks/useTimeout";
import SDKSingleton from "../../../SDK";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { setConnectivityStatus, setProgress, setScaleFactor } from "../../../state/slices/swipe";
import { RootState } from "../../../state/store";
import BrowserNotSupportedModal from "./modals/BrowserNotSupportedModal";
import DeviceNotSupportedModal from "./modals/DeviceNotSupportedModal";
import GloveModeModal from "./modals/sensitivity/GloveModeModal";
import PointerSpeedModal from "./modals/sensitivity/PointerSpeedModal";
import TouchSensitivityModal from "./modals/sensitivity/TouchSensitivityModal";
import ShouldAddToHomeScreenModal from "./modals/ShouldAddToHomeScreenModal";

import banknote from "../../../assets/img/03_scan/scan_banknote.png";
import banknote_placement from "../../../assets/img/03_scan/scan_placement.png";

import swipeArrow from "../../../assets/img/03_scan/scan_swipe-arrow.png";

import landing from "../../../assets/img/03_scan/scan_landing.png";

import cloud from "../../../assets/img/03_scan/scan_progress.png";
import error from "../../../assets/sounds/error.mp3";
import success from "../../../assets/sounds/success.mp3";

interface Props {}

// component
const FunctionalSwipeField = (props: Props) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const dragControls = useDragControls();

  const scaleFactor = useAppSelector((state: RootState) => state.swipe.scaleFactor);

  const [showModal_TouchSensitivity, setShowModal_TouchSensitivity] = useState(false);
  const [showModal_GloveMode, setShowModal_GloveMode] = useState(false);
  const [showModal_pointerspeed, setShowModal_pointerspeed] = useState(false);
  const [showModal_ShouldAddToHomeScreen, setShowModal_ShouldAddToHomeScreen] = useState(false);
  const [showModal_browserNotSupported, setShowModal_browserNotSupported] = useState(false);
  const [showModal_deviceNotSupported, setShowModal_deviceNotSupported] = useState(false);

  const successSound = useRef(new Sound(success));
  const errorSound = useRef(new Sound(error));

  const [errorCount, setErrorCount] = useState(0);

  const [flashRedAlert, setFlashRedAlert] = useState(false);
  const [flashGreenAlert, setFlashGreenAlert] = useState(false);

  const [sdk] = useState(SDKSingleton.getInstance().sdk);

  // configure sdk
  useEffect(() => {
    // FIXME remove!
    // iPhone 12Pro
    // var scale = getScaleFactor(460, 3);
    // dispatch(setScaleFactor(scale));

    sdk.resume();

    let initialisationSubject = sdk.getInitialisationSubject().subscribe((response) => {
      console.log("*) initialisationResponse", response);

      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

        if (!Number.isNaN(scale)) {
          dispatch(setScaleFactor(scale));
        }
      } else {
        setShowModal_deviceNotSupported(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        setShowModal_TouchSensitivity(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        setShowModal_GloveMode(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        setShowModal_pointerspeed(true);
        return;
      }
    });

    const usabilitySubject = sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      console.log("*) usabilityResponse", response);
      if (response.event === "device_not_supported") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "browser_not_supported") {
        setShowModal_browserNotSupported(true);
        return;
      }
      if (response.event === "display_too_small_displacement") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "display_small_should_add_to_home") {
        setShowModal_ShouldAddToHomeScreen(true);
        return;
      }
    });

    const detectionSuccessSubject = sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());
      handleSwipeSuccess();
    });

    const detectionErrorSubject = sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      clickErrorButton();
    });

    const interactionSubject = sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          break;
        case "changed":
          break;
        case "complete":
          dispatch(setProgress(0));
          break;
        default:
          break;
      }
    });

    const progressSubject = sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress, response.lastSwipePoint);
      dispatch(setProgress(response.progress));
    });

    const connectivitySubject = sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      dispatch(setConnectivityStatus(response.status));
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      sdk.attachToElement(screen);
    }

    return () => {
      initialisationSubject.unsubscribe();
      usabilitySubject.unsubscribe();
      progressSubject.unsubscribe();
      connectivitySubject.unsubscribe();
      detectionSuccessSubject.unsubscribe();
      detectionErrorSubject.unsubscribe();
      interactionSubject.unsubscribe();
    };
  }, []);

  useTimeout(() => {
    if (!scaleFactor) {
      let storageFactor = getScaleFactorFromLocalStorage();
      if (storageFactor) {
        dispatch(setScaleFactor(storageFactor));
      }
    }
  }, 3000);

  const handleSwipeSuccess = () => {
    sdk.pause();
    successSound.current.play();
    showGreenAlert();
    playSuccessAnimation();

    setTimeout(() => {
      navigate("/success", { replace: true });
    }, 2000);

    setTimeout(() => {
      dispatch(setProgress(0));
    }, 3000);
  };

  // FIXME selector is not updating when called from subscription
  // works fine when using with button onClick
  // temporary workaround: use hidden button
  const clickErrorButton = () => {
    let btn = document.getElementById("errorButton") as HTMLButtonElement;
    btn.click();
  };
  const handleSwipeError = () => {
    errorSound.current.sound.currentTime = 0;

    if (errorCount >= 4) {
      errorSound.current.play();
      navigate("/failure", { replace: true });
      setTimeout(() => {
        dispatch(setProgress(0));
      }, 1000);
    } else {
      setErrorCount(errorCount + 1);
      showRedAlert();
    }
  };

  const showRedAlert = () => {
    setFlashRedAlert(true);
  };
  const showGreenAlert = () => {
    setFlashGreenAlert(true);
  };

  const { play: playSuccessAnimation } = useMotionTimeline([
    ["#satelliteContainer", { top: [null, "-120%"] }, { duration: 0.3, easing: "linear" }],
  ]);

  const closeTouchModalAndRestartAnimation = () => {
    setShowModal_TouchSensitivity(false);
    setShowModal_pointerspeed(false);
    setShowModal_GloveMode(false);
  };

  return (
    <>
      <div id="swipeScreen" className="absolute top-0 left-0 w-screen h-full overflow-hidden bg-opacity-30 bg-landqart-darkBrown">
        {!scaleFactor && !(scaleFactor > 0) ? (
          <>
            <div className="absolute inset-0 flex items-center justify-center text-white bg-landqart-darkBrown bg-opacity-60">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <ScaledImage
              src={banknote_placement}
              id="placement"
              alt="placement"
              horizontalAlign="center"
              verticalAlign="center"
              horizontalOffset={-792}
              scaleFactor={scaleFactor}
            />

            {flashRedAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-screen bg-kb-red touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashRedAlert(false);
                  }}
                />
              </AnimatePresence>
            )}
            {flashGreenAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-screen bg-landqart-green touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashGreenAlert(false);
                  }}
                />
              </AnimatePresence>
            )}

            <motion.div
              className="absolute inset-0"
              initial={{ opacity: 0, translateX: "-60%", translateY: "10%" }}
              animate={{
                opacity: 1,
                translateX: "0%",
                translateY: "0%",
                transition: {
                  duration: 1,
                  delay: 0.7,
                  easings: "easeOut",
                },
              }}
            >
              <ScaledImage
                src={banknote}
                id="banknote"
                alt="banknote"
                horizontalAlign="center"
                verticalAlign="center"
                horizontalOffset={-792}
                scaleFactor={scaleFactor}
              />
            </motion.div>
            <ScaledImage
              src={landing}
              id="landing"
              alt="landing"
              horizontalAlign="center"
              verticalAlign="center"
              verticalOffset={-59}
              horizontalOffset={108}
              scaleFactor={scaleFactor}
            />
            <ScaledImage
              src={swipeArrow}
              id="swipeArrow"
              alt="swipeArrow"
              horizontalAlign="center"
              verticalAlign="center"
              verticalOffset={-50}
              horizontalOffset={108}
              scaleFactor={scaleFactor}
            />

            <motion.div
              id="satelliteContainer"
              className="absolute top-0 z-30 mx-auto"
              style={{ top: "50%", left: "50%" }}
              drag="y"
              dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
              dragElastic={1}
              dragControls={dragControls}
              dragListener={false}
            >
              <div
                style={{
                  // add offset for positioning
                  transform: `translateX(calc(-50% + ${108 * (scaleFactor || 1)}px)) translateY(${450 * (scaleFactor || 1)}px)`,
                }}
              >
                <div
                  id="satelliteShell"
                  className={`flex flex-col items-center px-5`}
                  style={{
                    paddingTop: 100 * scaleFactor,
                  }}
                  onPointerDown={(e) => {
                    dragControls.start(e);
                  }}
                >
                  <ScaledImageUnpositioned className="-pt-10" src={cloud} id="cloud" alt="cloud" scaleFactor={scaleFactor} />
                </div>
              </div>
            </motion.div>
          </>
        )}
      </div>

      <TouchSensitivityModal isOpen={showModal_TouchSensitivity} setIsOpen={closeTouchModalAndRestartAnimation} />
      <GloveModeModal isOpen={showModal_GloveMode} setIsOpen={closeTouchModalAndRestartAnimation} />
      <PointerSpeedModal isOpen={showModal_pointerspeed} setIsOpen={closeTouchModalAndRestartAnimation} />

      <ShouldAddToHomeScreenModal isOpen={showModal_ShouldAddToHomeScreen} setIsOpen={setShowModal_ShouldAddToHomeScreen} />
      <BrowserNotSupportedModal isOpen={showModal_browserNotSupported} setIsOpen={setShowModal_browserNotSupported} />
      <DeviceNotSupportedModal isOpen={showModal_deviceNotSupported} setIsOpen={setShowModal_deviceNotSupported} />

      <div className="absolute z-10 space-x-2 bottom-2">
        <button onClick={() => handleSwipeError()} id="errorButton" className="hidden">
          handleSwipeError
        </button>
        {/* <button onClick={() => handleSwipeSuccess()} id="successButton" className="">
          handleSwipeSuccess
        </button> */}
      </div>
    </>
  );
};

export default FunctionalSwipeField;
