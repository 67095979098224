import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/hooks";
import { setInvocationIsValid } from "../../state/slices/app";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpg";
import logo_prismade from "../../assets/img/00_general/prismade.svg";
import logo_landqart from "../../assets/img/00_general/landqart.svg";

const Start = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(setInvocationIsValid());
  }, []);

  return (
    <MotionDiv className="h-full">
      <div
        className="flex flex-col items-center justify-between h-full pb-10 bg-center bg-cover text-landqart-lightBrown pt-14"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <h1 className="text-5xl leading-snug">
          Interactive
          <br />
          Banknotes
        </h1>

        <div>
          <img src={logo_landqart} alt="Prismade Logo" className="h-10 mx-auto" />
          <img src={logo_prismade} alt="Prismade Logo" className="h-8 mx-auto mt-8" />
        </div>

        <div></div>
        <Button
          onClick={() => {
            navigate("/tutorial");
          }}
          title="Start"
          className="w-56 uppercase shadow-lg"
        />
      </div>
    </MotionDiv>
  );
};

export default Start;
