import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import usability1 from "../../assets/img/04_verify/verify_usability-01.png";
import usability2 from "../../assets/img/04_verify/verify_usability-02.png";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpg";
import logo_landqart from "../../assets/img/00_general/landqart_green.svg";
import logo_prismade from "../../assets/img/00_general/prismade_green.svg";

const staggerFadeIn = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.7,
      delayChildren: 1,
      staggerChildren: 0.5,
    },
  },
};
const scaleVariants = {
  initial: { opacity: 0, scale: 0.5 },
  animate: {
    opacity: 1,
    scale: [0.5, 1.2, 1],
    transition: {
      duration: 1.5,
    },
  },
};

const Failure = () => {
  let navigate = useNavigate();

  return (
    <MotionDiv className="h-full">
      <div
        className="flex flex-col items-center justify-between h-full px-8 pt-8 pb-6 text-center text-landqart-lightBrown bg-gradient-default"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <motion.div className="text-3xl font-medium text-landqart-green" variants={scaleVariants} initial="initial" animate="animate">
          Banknote could
          <br />
          not be verified.
        </motion.div>

        <motion.div variants={staggerFadeIn} initial="hidden" animate="show" className="flex flex-col overflow-hidden">
          <motion.div variants={staggerFadeIn} className="flex flex-col items-center overflow-hidden">
            <img src={usability1} alt="" className="object-contain h-full mx-auto mt-10 overflow-hidden" />
            <p className="mt-4">
              Place banknote on screen and hold
              <br />
              the banknote with your thumb.
            </p>
          </motion.div>
          <motion.div variants={staggerFadeIn} className="flex flex-col items-center overflow-hidden">
            <img src={usability2} alt="" className="object-contain w-full h-full mx-auto mt-10 overflow-hidden" />
            <p className="mt-4">
              Swipe up over the marked
              <br />
              stripe of the banknote.
            </p>
          </motion.div>
        </motion.div>

        <div className="w-full mt-8">
          <div className="px-6">
            <Button onClick={() => navigate("/")} title="Start Again" className="w-56 mx-auto" />
          </div>
          <div className="flex items-center justify-center gap-6 mt-6">
            <img src={logo_prismade} alt="Logo Prismade" className="h-6" />
            <img src={logo_landqart} alt="Logo Landqart" className="h-5 mb-1" />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Failure;
