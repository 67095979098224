import { ReactNode } from "react";
import { Dialog } from "@headlessui/react";

type Props = {
  isOpen: boolean;
  setIsOpen: any;
  title: string;
  children: ReactNode;
};

const Modal = (props: Props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
      className="absolute inset-0 z-50 overflow-y-auto"
    >
      <div className="flex items-center justify-center h-full p-10">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />

        <div className="relative mx-auto bg-white rounded min-w-screen-md max-w-9/10">
          <div className="grid p-4 text-center">
            <Dialog.Title>
              <p className="text-lg font-extrabold">{props.title}</p>
            </Dialog.Title>

            {props.children}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;
