import { motion } from "framer-motion";
import { useRef } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";

import bg from "../../assets/img/00_general/bg.jpg";
import logo_landqart from "../../assets/img/00_general/landqart_green.svg";
import logo_prismade from "../../assets/img/00_general/prismade_green.svg";
import bill from "../../assets/img/04_verify/verify_banknote.png";
import intaglio from "../../assets/img/04_verify/verify_intaglio.png";
import foil from "../../assets/img/04_verify/verify_security-foil.png";
import transparency from "../../assets/img/04_verify/verify_transparent-window.png";
import watermark from "../../assets/img/04_verify/verify_watermark.png";

import checkmark from "../../assets/img/04_verify/landqart-checkmark.json";

const scaleVariants = {
  initial: { opacity: 0, scale: 0.5 },
  animate: {
    opacity: 1,
    scale: [0.5, 1.2, 1],
    transition: {
      duration: 1.5,
    },
  },
};

const Success = () => {
  let navigate = useNavigate();

  const screenRef = useRef(null);

  return (
    <MotionDiv className="h-full">
      <motion.div className="h-full overflow-auto" ref={screenRef}>
        <div className="px-8 pt-8 pb-8 bg-center bg-repeat-y bg-contain text-landqart-lightBrown" style={{ backgroundImage: `url(${bg})` }}>
          <motion.div
            className="flex items-center justify-center text-3xl font-medium text-center text-landqart-green"
            variants={scaleVariants}
            initial="initial"
            animate="animate"
          >
            <Lottie animationData={checkmark} play={true} loop={false} className="h-18" speed={1.1} />
            <p className="pt-4 text-center">
              Banknote
              <br />
              authenticated.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.8, rotate: -120, x: "-50%" }}
            animate={{ opacity: 1, scale: 1, rotate: 0, x: "0%" }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 90,
              delay: 0.7,
            }}
          >
            <img src={bill} alt="bill" className="mx-auto mt-10 mb-10 h-72" />
          </motion.div>

          <div className="grid grid-cols-[auto,1fr] gap-y-6">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0.4, scale: 0.8 },
              }}
            >
              <motion.img src={watermark} alt="" className="w-[30vw] pr-4" />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0.4 },
              }}
            >
              <h2>Watermark</h2>
              <p className="pt-6">
                The importance of water and the river
                <br />
                Rhine is shown in a watermark image of the river traversing the corner of the note.
              </p>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0.4, scale: 0.8 },
              }}
            >
              <motion.img src={transparency} alt="" className="w-[30vw] pr-4" />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0.4 },
              }}
            >
              <h2>Transparent window with SPARK Live</h2>
              <p className="pt-6">
                Sicpa's "Spark Live" feature has been printed directly on the window so it can be viewed from both sides of the note.
                <br />
                The "Sandune Drops" effect is tied to the theme of water and the river watermark.
              </p>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0.4, scale: 0.8 },
              }}
            >
              <motion.img src={foil} alt="" className="w-[30vw] pr-4" />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0.4 },
              }}
            >
              <h2>Security Foil</h2>
              <p className="pt-6">
                A "KINEGRAM COSMIC Stripe"
                <br />
                Laminating foil has been applied, in full registration, appearing diagonally across the banknote, an industry first. The
                foil is, uniquely to Durasafe, completely embedded inside the substrate, with part of it exposed in a half window, and the
                remainder viewable in transmission.
              </p>
            </motion.div>

            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0.4, scale: 0.8 },
              }}
            >
              <motion.img src={intaglio} alt="" className="w-[30vw] pr-4" />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ margin: "-50% 0px -50% 0px" }}
              transition={{ duration: 0.7 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0.4 },
              }}
            >
              <h2>Intaglio Print</h2>
              <p className="pt-6">
                Intaglio print is part of what makes banknotes so distinctive. On the modern side of the note, its use and the contemporary
                design promote tactility and security. The print integrates the names of current and past staff members as microtext in the
                contour lines.
              </p>
            </motion.div>
          </div>

          <div className="grid justify-center grid-cols-1 gap-5 mt-16">
            <div className="w-full text-center">
              <a
                href="https://landqart.com/en/150-years-lq"
                target="_blank"
                rel="noreferrer"
                className="inline-flex items-center justify-center w-56 px-3 py-3 text-center rounded-full bg-landqart-green text-landqart-darkBrown"
              >
                <div className="w-full text-2xl">Learn more</div>
              </a>
            </div>

            <Button
              // reset state to empty, otherwise it will throw an error
              onClick={() => navigate("/", { state: {} })}
              title="Start Again"
              className="w-56 mx-auto"
            />
          </div>

          <div className="flex items-center justify-center gap-6 mt-6">
            <img src={logo_prismade} alt="Logo Prismade" className="h-6" />
            <img src={logo_landqart} alt="Logo Landqart" className="h-5 mb-1" />
          </div>
        </div>
      </motion.div>
    </MotionDiv>
  );
};

export default Success;
