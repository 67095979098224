import { Dialog } from "@headlessui/react";
import { useState } from "react";
import Button from "../../../../../components/UI/buttons/Button";
import TouchToggle from "../../../../../components/UI/TouchToggle";

interface Props {
  title: string;
  p1: string;
  p2: string;
  p3: string;
  isOpen: boolean;
  setIsOpen: any;
}

const SensitivityModal = (props: Props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Dialog open={props.isOpen} onClose={() => {}} className="absolute inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center h-full p-10">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />

        <div className="relative mx-auto rounded min-w-screen-md max-w-9/10 bg-gradient-to-b from-blue-100 to-white">
          <div className="grid p-4 text-center">
            <Dialog.Title>
              <h1
                className="text-lg"
                dangerouslySetInnerHTML={{
                  __html: props.title,
                }}
              />
            </Dialog.Title>

            <div className="flow-root mt-8">
              <ul className="-mb-8">
                <li>
                  <div className="relative pb-8">
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-kb-red" aria-hidden="true"></span>
                    <div className="relative flex space-x-3">
                      <div className="flex items-center justify-center w-8 h-8 font-semibold text-white rounded-full bg-kb-red">1</div>
                      <div
                        className="flex-1 pt-1 font-semibold"
                        dangerouslySetInnerHTML={{
                          __html: props.p1,
                        }}
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="relative pb-8">
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-kb-red" aria-hidden="true"></span>
                    <div className="relative flex space-x-3">
                      <div className="flex items-center justify-center w-8 h-8 font-semibold text-white rounded-full bg-kb-red">2</div>
                      <div
                        className="flex-1 pt-1 font-semibold"
                        dangerouslySetInnerHTML={{
                          __html: props.p2,
                        }}
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <div className="relative pb-8">
                    <div className="relative flex space-x-3">
                      <div>
                        <span className="flex items-center justify-center w-8 h-8 font-semibold text-white rounded-full bg-kb-red">3</span>
                      </div>
                      <div
                        className="flex-1 pt-1 font-semibold"
                        dangerouslySetInnerHTML={{
                          __html: props.p3,
                        }}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-8">
              <div className="flex flex-col items-center justify-around">
                <p className="text-edding-blue">OK done!</p>
                <TouchToggle isSet={isActive} setIsSet={setIsActive} />
              </div>
              <Button
                onClick={() => {
                  props.setIsOpen(false);
                }}
                title="Continue"
                disabled={!isActive}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SensitivityModal;
